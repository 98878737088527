import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'

export const parseFragments = (fragments, intl) => {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        )
                    }
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
        }
    })

    return fragmentDataMap
}

const StTestimonalsContainer = styled.div`
    max-width: 100%;
    min-height: 520px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 500px;
        max-width: 80%;
        padding-left: 120px;
        padding-right: 120px;
        padding-top: 44px;
        padding-bottom: 44px;
        background-color: white;
        ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
            `
    };
    }

    @media (min-width: ${settings.bp.medium.view}) {
        min-height: 400px;
    }
`

const StTestimonialsTitle = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 166.667% */
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 24px;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 28px;
        margin-bottom: 24px;
    }
`

const StTestimonialsText = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1.143px;
    text-transform: uppercase;
    margin-top: 24px;
`

const Arrow = css`
    position: absolute;
    transform: rotate(45deg);
    border: 2.7px solid black;
    z-index: 999;
    width: 10px;
    height: 10px;
    display: none;
    cursor: pointer;

    @media (min-width: ${settings.bp.small.view}) {
        width: 14px;
        height: 14px;
        display: block;
    }
`

const ArrowLeft = styled.div`
    ${Arrow}
    border-top: 0px;
    border-right: 0px;
    left: 15px;
    margin-top: 120px;
    @media (min-width: ${settings.bp.small.view}) {
        left: calc(10% + 15px);
        margin-top: 200px;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        margin-top: 150px;
    }
`

const ArrowRight = styled.div`
    ${Arrow}
    border-bottom: 0px;
    border-left: 0px;
    left: calc(100% - 15px);
    margin-top: 120px;
    @media (min-width: ${settings.bp.small.view}) {
        left: calc(90% - 29px);
        margin-top: 200px;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        margin-top: 150px;
    }
`

export const Testimonials = props => {
    const { testimonials, backgroundColor } = props
    const maxIndex = testimonials.length - 1
    const [index, setIndex] = useState(0)
    const [autoRotate, setAutoRotate] = useState(true)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }

    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 8000)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!testimonials) {
        return null
    }

    return (
        <StTestimonalsContainer
            backgroundColor={backgroundColor}
            onClick={() => {
                if (autoRotate) {
                    setAutoRotate(false)
                }
                if (mousePosition >= 50) {
                    let newIndex = index + 1 > maxIndex ? 0 : index + 1
                    setIndex(newIndex)
                } else {
                    let newIndex = index - 1 < 0 ? maxIndex : index - 1
                    setIndex(newIndex)
                }
            }}
            {...swipeHandler}
        >
            <div
                style={{
                    flex: 1,
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={`testimonial_${index}`}
            >
                {testimonials.length > 1 && (
                    <div>
                        <ArrowLeft
                            onClick={() => {
                                if (autoRotate) {
                                    setAutoRotate(false)
                                }
                                let newIndex =
                                    index + 1 > maxIndex ? 0 : index + 1
                                setIndex(newIndex)
                            }}
                        />
                        <ArrowRight
                            onClick={() => {
                                if (autoRotate) {
                                    setAutoRotate(false)
                                }
                                let newIndex =
                                    index - 1 < 0 ? maxIndex : index - 1
                                setIndex(newIndex)
                            }}
                        />
                    </div>
                )}
                <div>
                    <StTestimonialsTitle>
                        {testimonials[index]?.title}
                    </StTestimonialsTitle>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <img
                            style={{ maxWidth: '150px' }}
                            src={testimonials[index]?.image}
                        />
                    </div>
                    <StTestimonialsText>
                        {testimonials[index]?.text}
                    </StTestimonialsText>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                {testimonials.map((t, tIndex) => {
                    return (
                        <span
                            key={`dot-${tIndex}`}
                            style={
                                index === tIndex
                                    ? {
                                        backgroundColor: '#000',
                                        height: '7px',
                                        width: '7px',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                                    : {
                                        height: '7px',
                                        width: '7px',
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                            }
                        />
                    )
                })}
            </div>
        </StTestimonalsContainer>
    )
}

import React from 'react'
import get from 'lodash.get'
import styled, { css } from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import HeroSlider from 'components/HeroSlider'
import BestSellerSlider from 'components/NewHome/BestSellerSlider'
import { Spacing, SharedStyles, Container } from '../../../kenra-storybook/index'
import {
  Testimonials,
  parseFragments,
} from 'components/CustomLanding/MousseLandingPage/Testimonials'
const { StPageTitle } = SharedStyles


const ViewAllBtn = styled.a`
    width: 185px;
    height: 50px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 0;
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    line-height: 40pxpx;
    font-weight: 700;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 12px;
    letter-spacing: 2px;

    &:hover {
        cursor: pointer;
    }

`

function parseSection3Fragments(fragments, intl) {
  let fragmentDataMap = fragments.map(section => {
    let returnData = {}
    section.forEach(fragmentData => {
      switch (fragmentData.key) {
        case 'Title':
          returnData['title'] = fragmentData.value
          break
        case 'Button1Text':
          returnData['button1Text'] = fragmentData.value
          break
        case 'Button1Url':
          returnData['button1Url'] = fragmentData.value
          break
        case 'Image':
          returnData['image'] = fragmentData.value
          break
        default:
          break
      }
    })
    return {
      title: returnData.title,
      buttonText: returnData.button1Text,
      buttonUrl: returnData.button1Url,
      image: returnData.image,
    }
  })

  return fragmentDataMap
}

const SpraySlayRepeatLanding = ({
  intl,
  page,
  allShopifyProducts,
  allGoogleSheetProductsRow,
  allWhite,
}) => {
  const {
    stylistFavoritesProducts,
    carouselSlides
  } = page

  const testimonials = parseFragments(page.section1Data.fragments, null)

  const tipsData = parseSection3Fragments(
    page.section3Data.fragments,
    intl
  )[0]

  return (
    <>
      <Spacing removeSpaceTop removeSpaceBottom>
        <HeroSlider slides={page.banner} />
      </Spacing>

      <Spacing removeSpaceBottom>
        <Container>
          <StPageTitle style={{ marginBottom: '12px' }}>
            <h1
              style={{
                fontSize: '25px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '115%',
                letterSpacing: '2px',
                textTransform: 'uppercase',
              }}
            >
              {get(page, 'title')}
            </h1>
          </StPageTitle>
        </Container>
      </Spacing>

      {
        carouselSlides &&
        carouselSlides.map((slide, index) => {
          return (
            <Spacing key={`slide-${index}`} addHorizontalMargin removeSpaceTop removeSpaceBottom >


              <HeroSlider
                disableAutoplay={true}
                sliderConfig={{
                  settings: {
                    pagination: {
                      el: '.swiper-pagination',
                    },
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                }}
                additionalSlideStyle={{ paddingBottom: '40px' }}
                slides={[slide]} />


            </Spacing>
          )
        })
      }


      {testimonials && (
        <Spacing
          style={
            { backgroundColor: '#F8F8F7' }
          }
        >
          <Testimonials backgroundColor={'#F8F8F7'} testimonials={testimonials} />
        </Spacing>
      )}

      {tipsData && (
        <Spacing
          addHorizontalMargin
          removeSpaceBottom
          style={{ textAlign: 'center' }}
        >
          <StPageTitle style={{ marginBottom: '12px' }}>
            <h2
              style={{
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '115%',
                letterSpacing: '2px',
                textTransform: 'uppercase',
              }}
            >
              {get(tipsData, 'title')}
            </h2>
          </StPageTitle>
          <a href={tipsData.buttonUrl}>
            <img
              style={{ width: '100%' }}
              src={tipsData.image}
            />
          </a>

          <ViewAllBtn style={{
            marginTop: '12px',
            paddingBottom: '12px',
            minHeight: '60px'
          }} href={tipsData.buttonUrl}>
            {tipsData.buttonText}
          </ViewAllBtn>
        </Spacing>
      )}

      {stylistFavoritesProducts && stylistFavoritesProducts.length > 0 && (
        <Spacing
          removeSpaceBottom
          addHorizontalMargin
          style={
            allWhite
              ? { backgroundColor: '#FFF', marginTop: '-15px' }
              : { backgroundColor: '#F8F8F7', marginTop: '-15px' }
          }
        >
          <BestSellerSlider
            title={page.stylistFavoritesTitle}
            subtitle={''}
            products={page.stylistFavoritesProducts}
            shopifyProducts={allShopifyProducts}
            allGoogleSheetProductsRow={allGoogleSheetProductsRow}
            magicMousse={true}
          />
        </Spacing>
      )}

    </>
  )
}

export default injectIntl(SpraySlayRepeatLanding)
